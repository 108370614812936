th.tablesort-sortable {
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -o-user-select: none;
    user-select: none;
    cursor: pointer;
}

table .tablesort-sortable:after {
    content: "";
    display: inline-block;
    margin-left: 5px;
    vertical-align: 2px;
    margin-top: 0;
    visibility: hidden;
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;

    border-top: none;
    border-bottom: 4px solid #868686;
}

table .tablesort-desc:after {
    border-top: 4px solid #868686;
    border-bottom: none;
}

table .tablesort-asc,
table .tablesort-desc {
    //background-color:rgba(211, 211, 211, 0.25);
}

table .tablesort-sortable:hover:after,
table .tablesort-asc:after,
table .tablesort-desc:after {
    visibility: visible;
}
