.addNewButton {
    width: 80%;
    padding: 20px 0;
    background: #f8f8f8;
    border-radius: 6px;
    margin: 0 auto;
    text-align: center;
    font-size: 16px;
    color: #a8a8a8;
    border: 2px dashed #d5d5d5;
    cursor: pointer;
    font-weight: bold;
}

.addNewForm table tr td,
.addNewForm table tr:hover td {
    background: none;
}

.addNewForm {
    h3 {
        text-align: center;
    }
}

.networks table {
    margin-top: 20px;

    th {
        text-align: center;
    }

    tr {
        &:hover td {
            background: none;
        }

        &.active td {
            background: #f5f5f5 !important;
        }

        &.disabled {
            background: #f2f2f2 !important;
        }

        td {
            &.active &:hover td {
                background: #f5f5f5 !important;
            }
            &.namelogo {
                width: 280px;
                text-align: center;

                img.logo {
                    width: 170px;
                    margin-bottom: 10px;
                }

                span.name {
                    font-weight: bold;
                    font-size: 14px;
                }

                textarea {
                    background-color: white;
                }
            }

            &.properties {
                padding: 20px 0px;

                ul {
                    margin-bottom: 0px;

                    li {
                        margin-bottom: 10px;

                        i {
                            margin-top: 12px;
                        }

                        textarea {
                            width: 100%;
                            color: #757575;
                            border: 1px solid transparent;
                            background: white;
                            box-shadow: none;

                            &.code {
                                font-family: Menlo, Monaco, Consolas, "Courier New", monospace;
                                font-size: 14px;
                            }
                        }
                    }

                    li:last-child {
                        margin-bottom: 0px;
                    }
                }

                ul.active {
                    textarea {
                        //background-color: white;
                        color: #444;
                        border: 1px solid #cecece;
                        box-shadow: inset 0 2px 0 rgba(0, 0, 0, 0.1);
                    }
                }
            }

            &.edit {
                width: 120px;
                text-align: center;

                a {
                    width: 75%;
                }
            }
        }

        &.disabled textarea {
            background: transparent !important;
        }
    }
}
