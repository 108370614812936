.bootstrap-tagsinput {
    border: 1px solid #cecece;
    background: #fcfcfc;
    display: block;
    padding: 4px 5px 0px 5px;
    margin-bottom: 2px;
    color: #555;
    vertical-align: middle;
    border-radius: 3px;
    max-width: 100%;
    line-height: 22px;
    box-shadow: none;
    cursor: text;

    input[type="text"] {
        cursor: text;
        border: none;
        box-shadow: none;
        outline: none;
        background-color: transparent;
        padding: 0;
        margin-bottom: 5px;
        width: auto !important;
        max-width: inherit;
        transition: none;
        padding-left: 5px;
        font-size: 13px;
        margin-top: 2px;

        &:focus {
            border: none;
            box-shadow: none;
        }
    }

    .tag {
        &.label.label-info {
            font-weight: 400;
            padding: 5px 5px 5px 8px;
            font-size: 13px;
            background: @blueColor;
            display: inline-block;
            margin-bottom: 5px;
            margin-right: 0;
            border-radius: 2px;
        }

        margin-right: 2px;
        color: white;

        [data-role="remove"] {
            margin-left: 3px;
            cursor: pointer;
            font-family: "FontAwesome";
            font-weight: normal;

            &:after {
                content: "\f00d";
                padding: 0px 2px;
            }

            &:hover {
                box-shadow:
                    inset 0 1px 0 rgba(255, 255, 255, 0.2),
                    0 1px 2px rgba(0, 0, 0, 0.05);

                &:active {
                    box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
                }
            }
        }
    }
}
