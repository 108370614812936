/*.btn, btn-primary {
    //background: white;
    //border: 1px solid #ddd;
    //border-bottom: 2px solid #ddd;
    //color: #777;
    position: relative;

    &:before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
    }


    &:hover {
        color: #555;
        background: #fafafa;
        border-color: #bbb;
    }

    &:focus {
        background: white;
        border: 1px solid #ddd;
        border-bottom: 2px solid #ddd;
    }

    &:active, &.active, &.editable-open {
        border-bottom: 1px solid #ddd;
        box-shadow: inset 0 2px 0px rgba(0,0,0,0.1);
        background: #fafafa;
        padding-top: 7px;
        padding-bottom: 6px;

        &.btn-xs {
            padding-top: 2px;
            padding-bottom: 0px;
        }

        &.btn-sm {
            padding-top: 6px;
            padding-bottom: 5px;
        }

        &.btn-lg {
            padding-top: 12px;
            padding-bottom: 7px;
        }

        &.editable-open {
            color: #555;
            background: #ddd;
            border-color: #bbb;
        }
    }

    &.btn-primary {
        background-color: #428bca;
        border-color: #3174AD;
        color: white;

        &:hover {
            background-color: darken(#428bca,2);
        }
    }

    &.btn-success {
        background-color: #47a447;
        border-color: #398439;
        color: white;

        &:hover {
            background-color: darken(#47a447,2);
        }
    }


    &:focus {
        outline: none;
    }
}

.btn-group {
    &.open {
        .dropdown-toggle {
            box-shadow: inset 0 2px 0px rgba(0,0,0,0.125);
            -webkit-box-shadow: inset 0 2px 0px rgba(0,0,0,0.125);
            border-bottom: 1px solid #bbb;
            padding-top: 7px;
            padding-bottom: 6px;

            &.btn-sm {
                padding-top: 6px;
                padding-bottom: 5px;
            }
        }
    }

    .dropdown-toggle {
        .flag.small {
            width: 20px;
            margin-left: 0;
        }
    }
}*/

.well {
    min-height: 20px;
    padding: 19px;
    margin-bottom: 20px;
    background-color: #f5f5f5;
    border-radius: 4px;
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.15);
    border: none;
}

table.table {
    tr {
        &:hover {
            td {
                background: #f5f5f5;
            }
        }

        td {
            vertical-align: middle;
        }
    }
}
