@font-face {
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 400; //Normal;
    src:
        local("Montserrat Regular"),
        local("Montserrat-Regular"),
        url(https://fonts.gstatic.com/s/montserrat/v10/zhcz-_WihjSQC0oHJ9TCYPk_vArhqVIZ0nv9q090hN8.woff2)
            format("woff2");
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074,
        U+20AC, U+2212, U+2215;
}

@font-face {
    font-family: "Martel";
    font-style: normal;
    font-weight: 400; //Normal
    src:
        local("Martel"),
        local("Martel-Regular"),
        url(https://fonts.gstatic.com/s/martel/v1/UqHF0_T0x2A12j9bgYu4DQ.woff2) format("woff2");
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074,
        U+20AC, U+2212, U+2215;
}

@font-face {
    font-family: "AvenirLTStd-Medium";
    src: url("/legacy-app/fonts/2F65A9_3_0.eot");
    src:
        url("/legacy-app/fonts/2F65A9_3_0.eot?#iefix") format("embedded-opentype"),
        url("/legacy-app/fonts/2F65A9_3_0.woff") format("woff"),
        url("/legacy-app/fonts/2F65A9_3_0.ttf") format("truetype");
}

@font-face {
    font-family: "AvenirLTStd-Light";
    src: url("/legacy-app/fonts/2F65A9_6_0.eot");
    src:
        url("/legacy-app/fonts/2F65A9_6_0.eot?#iefix") format("embedded-opentype"),
        url("/legacy-app/fonts/2F65A9_6_0.woff") format("woff"),
        url("/legacy-app/fonts/2F65A9_6_0.ttf") format("truetype");
}
