.dashboard {
    .panel-list-wrapper {
        .panel-list {
            .panel-item {
                padding: 10px 16px;
                background: #f7f7f7;
                border-radius: 3px;
                border: 1px solid #ddd;
                margin-bottom: 10px;
                position: relative;

                .panel-actions {
                    position: absolute;
                    top: 10px;
                    right: 10px;
                }
            }
        }
    }
}
