@-webkit-keyframes ngdialog-flyin {
    0% {
        opacity: 0;
        -webkit-transform: translateY(-25px);
        transform: translateY(-25px);
    }

    100% {
        opacity: 1;
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }
}

@keyframes ngdialog-flyin {
    0% {
        opacity: 0;
        -webkit-transform: translateY(-25px) scale(0.85);
        transform: translateY(-25px) scale(0.85);
    }

    100% {
        opacity: 1;
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }
}

@-webkit-keyframes ngdialog-flyout {
    0% {
        opacity: 1;
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }

    100% {
        opacity: 0;
        -webkit-transform: translateY(-25px);
        transform: translateY(-25px);
    }
}

@keyframes ngdialog-flyout {
    0% {
        opacity: 1;
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }

    100% {
        opacity: 0;
        -webkit-transform: translateY(-25px);
        transform: translateY(-25px);
    }
}

.ngdialog {
    padding-bottom: 160px;
    padding-top: 160px;

    &.contact-us-dialog {
        .ngdialog-content {
            width: 550px;
        }
    }
}

.ngdialog.ngdialog-closing .ngdialog-content {
    -webkit-animation: ngdialog-flyout 0.5s;
    animation: ngdialog-flyout 0.5s;
}

.ngdialog .ngdialog-content .ngdialog-tabs {
    position: absolute;
    top: 40px;
    left: 0;
    width: 100%;

    .nav.nav-tabs {
        border-bottom: 1px solid #e5e5e5;
        background: none;
        margin: 10px 20px 0 20px;

        .nav-item {
            margin-right: 35px;
            margin-bottom: 0;

            &:last-child {
                a.nav-link {
                    border: none;
                }
            }

            a.nav-link {
                border-radius: 0;
                border: none;
                padding: 11px 10px 12px 10px;

                &:active,
                &:hover,
                &:focus {
                    outline: none;
                    color: #0275d8;
                }

                &.active {
                    color: #333;
                    border: none;
                    position: relative;

                    &:after {
                        left: 50%;
                        border: solid transparent;
                        content: " ";
                        height: 0;
                        width: 0;
                        position: absolute;
                        pointer-events: none;
                        border-color: rgba(255, 255, 255, 0);
                        border-bottom-color: #fff;
                        border-width: 8px;
                        margin-left: -8px;
                        bottom: -1px;
                    }

                    &:before {
                        left: 50%;
                        border: solid transparent;
                        content: " ";
                        height: 0;
                        width: 0;
                        position: absolute;
                        pointer-events: none;
                        border-color: rgba(125, 125, 125, 0);
                        border-bottom-color: #e5e5e5;
                        border-width: 9px;
                        margin-left: -9px;
                        bottom: -1px;
                    }
                }
            }
        }
    }
}

.ngdialog .ngdialog-content {
    -webkit-animation: ngdialog-flyin 0.35s;
    animation: ngdialog-flyin 0.35s;
    background: #fff;
    border-radius: 5px;
    margin: 0 auto;
    max-width: 100%;
    padding: 50px 20px 20px 20px;
    position: relative;
    width: 450px;
}

.ngdialog .ngdialog-header {
    background: 0 0;
    height: 45px;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    border-bottom: 1px solid #efefef;
    padding: 12px 20px;
    color: #333;
    font-size: 16px;

    .close-button {
        position: absolute;
        right: 0;
        top: 0;
        height: 45px;
        width: 45px;
        opacity: 0.75;
        background-position: 50% 50%;
        background-size: 25%;
        background-repeat: no-repeat;
        cursor: pointer;
        border-radius: 0 5px 0 0;
        border-left: 1px solid #ddd;
        text-align: center;
        font-size: 18px;
        line-height: 38px;

        &:hover {
            background-color: #f7f8fb;
        }
    }
}

.ngdialog .ngdialog-close {
    border-radius: 5px;
    cursor: pointer;
    position: absolute;
    right: 0;
    top: 0;
}

.ngdialog .ngdialog-close:before {
    background: 0 0;
    border-radius: 3px;
    color: #bbb;
    content: "\00D7";
    font-size: 26px;
    font-weight: 400;
    height: 30px;
    line-height: 26px;
    position: absolute;
    right: 7px;
    text-align: center;
    top: 9px;
    width: 30px;
}

.ngdialog .ngdialog-close:hover:before,
.ngdialog .ngdialog-close:active:before {
    color: #777;
}

.ngdialog .ngdialog-message {
    margin-bottom: 0.5em;
    padding: 15px 5px;
}

.ngdialog .ngdialog-input {
    margin-bottom: 1em;
}

.ngdialog .ngdialog-buttons {
    *zoom: 1;
    border-top: 1px solid #f0f0f0;
    padding-top: 20px;
}

.ngdialog .ngdialog-buttons:after {
    content: "";
    display: table;
    clear: both;
}

.ngdialog .ngdialog-button {
    float: right;
    margin: 0 0 0 0.5em;
}

.ngdialog .ngdialog-button:focus {
    -webkit-animation: ngdialog-pulse 1.1s infinite;
    animation: ngdialog-pulse 1.1s infinite;
    outline: none;
}

@media (max-width: 568px) {
    .ngdialog .ngdialog-button:focus {
        -webkit-animation: none;
        animation: none;
    }
}

.ngdialog .ngdialog-button.ngdialog-button-primary {
    //background: #3288e6;
    //color: #fff;
}

.ngdialog .ngdialog-button.ngdialog-button-secondary {
    //background: #e0e0e0;
    //color: #777;
}
