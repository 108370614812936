.adminpanel {
    padding-top: 50px;

    input {
        &::placeholder {
            color: #dadada;
        }

        &::-webkit-input-placeholder {
            color: #dadada;
        }

        &:-moz-placeholder {
            color: #dadada;
        }

        &::-moz-placeholder {
            color: #dadada;
        }

        &:-ms-input-placeholder {
            color: #dadada;
        }
    }

    nav {
        background-color: #fff;
        border-bottom: 1px solid #bac0ca !important;
        box-shadow: 0 0 13px #c9cfda;

        div.navbar-header {
            img {
                width: 35px;
                margin-top: -7px;
            }
        }
    }

    .features-dirty {
        position: fixed;
        top: 136px;
        left: 0;
        width: 100%;
        background: #f9ecaa;
        height: 35px;
        color: #b3861c;
        line-height: 35px;
        padding: 0 20px;

        button {
            padding: 3px 7px;
            margin-left: 10px;
        }
    }
    .create-account-message {
        label {
            display: block;
        }
        input {
        }
    }
}

.panel-dialog {
    .ngdialog-content {
        width: 600px;
    }
}
