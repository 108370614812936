.accounts {
    div.toggle-switch {
        min-width: 70px;

        .toggle-switch-animate span {
            padding: 2px;
        }
    }

    #filter-wrapper {
        padding: 10px;
        display: flex;
        height: 55px;

        #search {
            flex: 1;
        }

        #created-picker {
            margin: 0 0 0 30px;

            #daterangeInput {
                width: 40px;
                height: 35px;
                text-align: center;
                margin-left: 5px;
                padding: 8px 8px 8px 30px;
                background: url("/legacy-app/img/calendar-icon.gif") no-repeat white;
                background-position: 10px;
                cursor: pointer;
                border: 1px solid #ddd;
                border-bottom: 2px solid #ddd;
                border-radius: 4px;
                color: #777;
                transition: 0.7s ease;

                &:hover {
                    width: 220px;
                    color: #555;
                    border-color: #bbb;
                    box-shadow: none;
                }
            }
        }

        .type {
            margin: 0 0 0 30px;

            div.btn-group {
                margin: 0 0 0 5px;
            }
        }
    }

    table.table {
        margin-top: 0;

        span.closed {
            color: red;
            font-style: italic;
        }

        span.unconfirmed {
            font-style: italic;
        }
    }
}

#account-details {
    .modal-backdrop {
        z-index: 0;
    }

    .modal-dialog {
        width: 780px;

        .loader {
            min-height: 500px;
            width: 780px;
            position: relative;
        }

        .modal-header {
            position: relative;
            padding: 18px;
            padding-bottom: 0;
            margin-bottom: -16px;
            border-bottom: none;

            .logo-wrapper {
                width: 170px;
                height: 100px;
                margin-right: 18px;

                .logo {
                    width: 100%;
                    height: 100%;
                    background-size: contain;
                    background-repeat: no-repeat;
                    background-position: center;
                }
            }

            .name {
                h1 {
                    font-size: 30px;
                    margin: 5px 0;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    overflow: hidden;
                    max-width: 445px;
                }

                span.email,
                span.slug {
                    font-size: 13px;
                }

                span.email {
                    margin-right: 20px;
                }

                span.email,
                span.email:hover {
                    color: #444;
                }
            }

            .activity {
                margin: 6px 10px 0 0;

                button {
                    font-weight: 700;
                    font-size: 20px;
                    padding: 3px 10px;
                    display: block;
                    margin: 0 auto 5px;
                }
            }

            .close-button {
                background: #444;
                position: absolute;
                top: -11px;
                right: -11px;
                font-size: 18px;
                color: #fff;
                border: 3px solid #fff;
                border-radius: 50%;
                width: 34px;
                height: 34px;
                text-align: center;
                line-height: 27px;

                &:hover {
                    background: black;
                }
            }
        }

        ul.modal-navigation {
            //padding: 0 75px 0 190px;
            //background: none;
            border-top: 1px solid #e5e5e5;
            border-bottom: 1px solid #e5e5e5;

            li {
                display: inline-block;
                width: 130px;
                text-align: center;

                a {
                    border-right: 1px solid #e5e5e5;
                    padding: 6px 15px;
                    font-weight: bold;
                    text-transform: uppercase;
                }

                &:first-child {
                    border-left: 1px solid #e5e5e5;
                }

                &.active {
                    background: #ededed;

                    a {
                        color: #444;
                    }
                }
            }
        }

        .modal-body {
            padding: 18px;
            overflow: auto;

            .activity {
                table {
                    tr {
                        height: 42px;
                    }

                    td:last-child {
                        width: 160px;
                    }

                    td {
                        .progress {
                            margin-bottom: 0px;
                        }
                    }
                }
            }

            .timeline {
                text-align: center;
                height: 60px;
                margin-bottom: 18px;

                .flex-wrapper {
                    display: flex;

                    .event {
                        width: 90px;

                        span {
                            display: block;

                            &.name {
                                font-weight: bold;
                            }

                            &.date {
                                font-size: 13px;
                            }
                        }
                    }
                }

                .line {
                    background: #444;
                    height: 2px;
                    margin: -29px auto;
                }
                // Workaround because of bug :F
                // https://code.google.com/p/chromium/issues/detail?id=452606
                &.three,
                &.two,
                &.one {
                    .flex-wrapper {
                        justify-content: center;
                    }
                }

                &.full {
                    .flex-wrapper {
                        justify-content: space-between;

                        .event {
                            margin: 0 0;
                        }
                    }

                    .line {
                        width: 655px;
                    }
                }

                &.three {
                    .event {
                        margin: 0 65px;
                    }

                    .line {
                        width: 438px;
                    }
                }

                &.two {
                    .event {
                        margin: 0 120px;
                    }

                    .line {
                        width: 322px;
                    }
                }

                &.one {
                    .line {
                        display: none;
                    }
                }
            }

            .kpis {
                text-align: center;
                display: flex;
                justify-content: space-around;

                .kpi {
                    width: 160px;
                    padding: 6px 0px;

                    &.pressed,
                    &:hover {
                        background: #ededed;
                    }

                    h2 {
                        margin: 0 0 2px 0;
                        cursor: pointer;
                    }

                    label {
                        margin: 0;
                        font-weight: normal;
                        cursor: pointer;
                    }
                }
            }

            .users {
                //height: 258px;
                height: auto;
                overflow: visible;
                //overflow-y: auto;

                table {
                    td {
                        img.profile-thumb {
                            padding: 2px;
                            border: 1px solid #ddd;
                            margin-right: 5px;
                        }
                    }
                }
            }

            .chart-wrapper {
                padding: 14px 0;
            }

            .graph-metadata {
                width: 175px;
                margin: 12px 3px 0;

                table td {
                    line-height: 1.2;
                }

                table.summary {
                    margin: 0 0 8px;

                    tr {
                        td:last-child {
                            text-align: right;
                        }
                    }
                }

                .dist-bar {
                    .progress {
                        margin-bottom: 0;
                        height: 30px;

                        .progress-bar {
                            line-height: 30px;
                        }
                    }
                }
            }

            .manage {
                max-height: 100%;
                overflow-y: auto;

                table.features,
                table.actions {
                    tr td:last-child {
                        text-align: right;
                    }

                    tr td:first-child i {
                        font-size: 16px;
                        margin-right: 4px;
                    }
                }

                table.features {
                    tr td:last-child {
                        padding: 6px 8px;
                    }

                    tr {
                        height: 43px;
                    }
                }

                .actions-wrapper {
                    position: relative;

                    table.actions {
                        tr {
                            height: 42px;

                            td:last-child {
                                padding: 5px 8px;

                                i {
                                    color: @greenColor;
                                }
                            }

                            td div.bootstrap-select {
                                margin-bottom: 0px;

                                button {
                                    padding: 4px 12px;
                                }
                            }
                        }
                    }
                }
            }
        }

        .modal-footer {
            cursor: pointer;
            text-align: center;
            padding: 0;
            height: 50px;
            line-height: 49px;
            position: relative;
            z-index: 10;

            .close-account {
                height: 100%;
                width: 20%;
                border-bottom-left-radius: 4px;

                &:hover {
                    background-color: #e6e6e6;
                }

                &:active {
                    background-color: #e6e6e6;
                    box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
                }
            }

            .to-account {
                height: 100%;
                width: 80%;
                border-bottom-right-radius: 4px;
                background-color: @greenColor;
                border-color: darken(@greenColor, 10);
                color: white;
                font-weight: bold;
                font-size: 20px;

                &:hover {
                    background-color: darken(@greenColor, 3);
                }

                &:active {
                    background-color: darken(@greenColor, 3);
                    box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
                }
            }
        }
    }
}
