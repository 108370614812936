@import "../bootstrap.min.less";
@import "../controls.less";
@import "../bs-select.less";
@import "../angular-tablesort.less";
@import "../libs/daterangepicker-bs3.less";
@import "../libs/angular-toggle.less";
@import "../libs/bootstrap-select.less";
@import "../libs/bootstrap-tagsinput.less";
@import "../adminpanel.less";
@import "../accounts.less";
@import "../networks.less";
@import "../libs/datetimepicker.less";
@import "../dashboard.less";
@import "../fonts.less";

@import "../ngdialog.less";
@import "../ngdialog-theme.less";

@blueColor: #33a0c0;
@greenColor: #50b480;
@redColor: #e46e6e;
@purpleColor: #61506d;
@yellowColor: #e8c158;
@extraImprColor: #bf8bd3;
@monthlyImprColor: #31c596;

.btn {
    /* Jocke, bring this back if needed, but don't break all other buttons :P
    background: white;
    border: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
    color: #777;
    position: relative;*/

    &.btn-danger {
        color: #fff;
        background-color: @redColor;
        border-color: darken(@redColor, 10);

        &:hover {
            background-color: darken(@redColor, 3);
        }
    }

    &.btn-primary {
        background-color: @blueColor;
        border-color: darken(@blueColor, 10);
        color: white;

        &:hover {
            background-color: darken(@blueColor, 3);
        }
    }

    &.btn-success {
        background-color: @greenColor;
        border-color: darken(@greenColor, 10);
        color: white;

        &:hover {
            background-color: darken(@greenColor, 3);
        }
    }
}

.progress-bar-success {
    background-color: @greenColor;
}

*,
*:focus,
button:focus,
button:visited,
button:active,
a,
a:focus,
a:visited,
a:active,
a:hover {
    outline: 0;
    text-decoration: none;
}

html,
body {
    //height: 100%;
    //color: #e5e5e5;
    font-family: "Droid Sans", sans-serif;
    /*transform-style: preserve-3d;
    -webkit-transform-style: preserve-3d;
    -moz-transform-style: preserve-3d;
    -ms-transform-style: preserve-3d;*/
}

body {
    background: #f2f2f2;
    background-attachment: fixed;
    background-position: center center;
    background-size: 180px 200px;

    &.modal-open {
        position: fixed;
        width: 100%;
    }
}

html {
    //overflow-y: scroll;
}

a {
    cursor: pointer;
}

em {
    color: #999;
    font-style: normal;
}

canvas {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 999999999999999;
}

input[type="checkbox"] {
    margin: 0 0 3px 0;
    vertical-align: middle;
}

textarea[readonly],
input[type="text"][readonly] {
    background: white;
}

textarea,
input[type="text"],
input[type="email"] {
    border: 1px solid #cecece;
    background: #fcfcfc;
    border-radius: 3px;
    margin: 0px;
    padding: 10px;
    font-family: "Droid Sans", sans-serif;
    font-size: 15px;
    resize: none;
    color: #444;
    box-shadow: none;

    &.error {
        border: 1px solid rgb(211, 77, 77);
        background: rgb(255, 244, 244);
    }

    &[readonly] {
        cursor: text;
    }

    &:focus {
        border: 1px solid rgb(105, 159, 228);
        background: white;
        box-shadow: none;
        //box-shadow: inset 0 2px 0px rgba(0, 0, 0, 0.1),0 0 0 3px rgba(134, 173, 255, 0.15);
        &.bottom-less {
            box-shadow: inset 0 2px 0px rgba(0, 0, 0, 0.1);
            border: 1px solid #cecece;
            border-bottom: none;
            background: #fcfcfc;
        }

        &.error {
            border: 1px solid rgb(211, 77, 77);
            background: rgb(255, 244, 244);
            box-shadow:
                inset 0 2px 0px rgba(0, 0, 0, 0.1),
                0 0 0 3px rgb(255, 244, 244);
        }
    }

    &.saving,
    &.saving:focus {
        transition: none;
        border: 1px solid #5ead31;
        //box-shadow: #C2E9BD 0px 0px 0px 4px, inset 0 1px 2px rgba(0, 0, 0, 0.15);
    }

    &::-webkit-input-placeholder {
        /* WebKit browsers */
        color: #888;
    }

    &::-moz-placeholder {
        /* Mozilla Firefox 19+ */
        color: #888;
    }

    &:-ms-input-placeholder {
        /* Internet Explorer 10+ */
        color: #888;
    }
}

input[type="text"],
input[type="password"] {
    padding: 5px 10px;
}

.textarea-bottom-panel {
    background: #f8f8f8;
    padding: 10px;
    border-radius: 0 0 4px 4px;
    border: 1px solid #ddd;
    border-top: 1px dashed #ddd;
}

h1,
h2,
h3,
h4,
h5,
h6,
h7,
h8,
h9 {
    font-family: "Droid Sans", sans-serif;
    font-weight: 700;
    letter-spacing: -1px;
}

h3 {
    margin-top: 8px;
    font-weight: normal;
    color: #888;
}

h4 {
    margin-top: 5px;
    font-weight: normal;
    color: #888;
    font-size: 16px;
}

.container {
    min-width: 700px;
}

@media (min-width: 1200px) {
    .container {
        max-width: 1200px;
    }
}

.view {
    margin-top: 15px;
}

#body-wrapper {
    min-height: 100%;
    height: auto !important;
    height: 100%;
    margin: 0 auto -75px;

    .main-content {
        margin-top: 55px;
    }
}

.max-width {
    max-width: 1300px;
    margin: 0 auto;
    min-width: 900px;
    padding: 0 50px;
}

.sub-menu-wrapper {
    padding-left: 7px;
    transition: all 0.3s ease;
    display: block !important;
    transition-delay: 0.2s;
    transform: translateY(-50px);
    -webkit-transform: translateY(-50px);
    -moz-transform: translateY(-50px);
    -ms-transform: translateY(-50px);

    &.isShown {
        transform: translateY(0px);
        -webkit-transform: translateY(0px);
        -moz-transform: translateY(0px);
        -ms-transform: translateY(0px);
    }

    .sub-menu {
        list-style-type: none;
        margin: 8px 0 10px 46px;
        padding: 0;
        height: 38px;

        &:after {
            content: ".";
            visibility: hidden;
            clear: both;
        }

        li {
            a {
                //transition: color 0.3s;
                margin: 10px 19px;
                padding: 2px 11px;
                display: block;
                color: #999;
                text-decoration: none;
                font-weight: bold;
                text-shadow: none; //0 -1px 0 white;
            }

            display: block;
            float: left;
            min-width: 110px;
            font-size: 14px;
            text-align: center;
            cursor: pointer;
            letter-spacing: -0.04em;

            &:hover {
                a {
                    color: #544768;
                }
            }

            &:first-child {
                border-radius: 3px 0 0 0;
            }

            &.active {
                a {
                    background: #544768; //darken(@mainColor, 5);
                    color: white;
                    border-radius: 100px;
                }
            }
        }
    }
}

#push,
#footer {
    height: 75px;
}

#footer {
    background-color: #e9e9e9;

    .container {
        opacity: 1;
        text-align: left;
        width: auto;
        padding: 0 50px;

        img {
            margin-right: 20px;
        }

        p {
            padding-top: 21px;
            font-size: 13px;
            color: #999;
        }
    }
}

#butterfly {
    width: 126px;
    height: 95px;
    position: absolute;
    left: 50%;
    top: 50%;
    margin-left: -63px;
    margin-top: -47px;
    transform: rotate(36deg) scale(0.5);
    -webkit-transform: rotate(36deg) scale(0.5);
    -moz-transform: rotate(36deg) scale(0.5);
    transform: rotate(36deg) scale(0.5);
    z-index: 9999999999;

    .wing-left {
        transform-origin: 100% 50%;
        -webkit-transform-origin: 100% 50%;
        -moz-transform-origin: 100% 50%;
        -ms-transform-origin: 100% 50%;
        -webkit-animation: wingLeftAnimation 1s ease-in-out 0s infinite;
        -moz-animation: wingLeftAnimation 1s ease-in-out 0s infinite;
        -ms-animation: wingLeftAnimation 1s ease-in-out 0s infinite;
        -o-animation: wingLeftAnimation 1s ease-in-out 0s infinite;
        animation: wingLeftAnimation 1s ease-in-out 0s infinite;
        position: absolute;
        top: 0px;
        left: 0px;
        width: 70px;
        height: 87px;
        background: url(/legacy-app/img/wing-left.png);
        background-repeat: no-repeat;
        background-size: cover;
    }

    .wing-right {
        transform-origin: 0% 50%;
        -webkit-transform-origin: 0% 50%;
        -moz-transform-origin: 0% 50%;
        -ms-transform-origin: 0% 50%;
        -webkit-animation: wingRightAnimation 1s ease-in-out 0s infinite;
        -moz-animation: wingRightAnimation 1s ease-in-out 0s infinite;
        -ms-animation: wingRightAnimation 1s ease-in-out 0s infinite;
        -o-animation: wingRightAnimation 1s ease-in-out 0s infinite;
        animation: wingRightAnimation 1s ease-in-out 0s infinite;
        position: absolute;
        bottom: 0px;
        right: 0px;
        width: 60px;
        height: 94px;
        background: url(/legacy-app/img/wing-right.png);
        background-repeat: no-repeat;
        background-size: cover;
    }
}

.thumb {
    display: inline-block;
    margin-right: 5px;
    vertical-align: middle;
    width: 30px;
    height: 28px;
    background: #ccc url(/legacy-app/img/banners/preview-300x250.png) no-repeat;
    background-position: center center;
    background-size: cover;
    border-radius: 3px;
}

.bf-menu.dropdown-menu {
    min-width: 100px;

    &.pull-right {
        left: 50%;
        margin-left: -50px;
    }

    & > li > a {
        padding: 5px 10px;
        text-align: center;

        &:hover,
        &:focus {
            background: #69668a;
        }
    }
}

.bf-menu.dropdown-menu::before {
    border-bottom: 7px solid rgba(0, 0, 0, 0.2);
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    content: "";
    display: inline-block;
    left: 50%;
    margin-left: -7px;
    position: absolute;
    top: -7px;
}

.bf-menu.dropdown-menu::after {
    border-bottom: 6px solid #ffffff;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    content: "";
    display: inline-block;
    left: 50%;
    margin-left: -6px;
    position: absolute;
    top: -6px;
}

.btn-group.pull-right > .dropdown-menu::before {
    left: inherit;
    right: 9px;
}

.btn-group.pull-right > .dropdown-menu::after {
    left: inherit;
    right: 10px;
}

.nav.nav-tabs {
    //border: none;
    padding-top: 10px;
    background: #f5f5f5;

    & > li {
        &.empty {
            margin-left: 23px;
        }

        &.active {
            & > a {
            }
        }

        & > a {
        }
    }
}

.loading-overlay {
    z-index: 99999999999;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0px;
    left: 0px;
    background: rgba(255, 255, 255, 1);
    pointer-events: none;
    border-radius: 5px;

    i {
        position: absolute;
        left: 50%;
        top: 50%;
        font-size: 42px;
        color: #ccc;
        margin-left: -21px;
        margin-top: -21px;
    }
}

.inlinespinner {
    width: 65px;
    height: 65px;
    text-align: center;
    font-size: 10px;
}

.spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-left: -30px;
    margin-top: -30px;
    width: 65px;
    height: 65px;
    text-align: center;
    font-size: 10px;
}

.spinner > div,
.inlinespinner > div {
    height: 100%;
    width: 7px;
    margin: 0 3px 0 0;
    background-color: #ddd;
    display: inline-block;
    -webkit-animation: stretchdelay 1.2s infinite ease-in-out;
    animation: stretchdelay 1.2s infinite ease-in-out;
}

.spinner.dark > div,
.inlinespinner.dark > div {
    background-color: #d5d5d5;
}

.spinner .rect2,
.inlinespinner .rect2 {
    -webkit-animation-delay: -1.1s;
    animation-delay: -1.1s;
}

.spinner .rect3,
.inlinespinner .rect3 {
    -webkit-animation-delay: -1s;
    animation-delay: -1s;
}

.spinner .rect4,
.inlinespinner .rect4 {
    -webkit-animation-delay: -0.9s;
    animation-delay: -0.9s;
}

.spinner .rect5,
.inlinespinner .rect5 {
    -webkit-animation-delay: -0.8s;
    animation-delay: -0.8s;
}

#daterangeInput {
    width: 220px;
    text-align: center;
    margin-left: 5px;
    padding: 8px 8px 8px 30px;
    background: url("http://www.guinness-storehouse.com/en/images/calendar-icon.gif") no-repeat;
    background-position: 10px;
    border: 1px dashed #ddd;
    cursor: pointer;

    &:hover {
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.25);
    }
}

.table {
    &.pointer tr:hover {
        cursor: pointer;
    }

    th {
        text-align: left;
    }

    div.statuscircle {
        width: 20px;
        height: 20px;
        border-radius: 1000px;

        &.green {
            background: rgb(77, 165, 77);
        }

        &.yellow {
            background: rgb(236, 207, 94);
        }

        &.red {
            background: #ff7966;
        }
    }
}

.spinner > div {
    height: 100%;
    width: 7px;
    margin: 0 3px 0 0;
    background-color: #ddd;
    display: inline-block;
    -webkit-animation: stretchdelay 1.2s infinite ease-in-out;
    animation: stretchdelay 1.2s infinite ease-in-out;
}

.spinner.dark > div {
    background-color: #d5d5d5;
}

.spinner .rect2 {
    -webkit-animation-delay: -1.1s;
    animation-delay: -1.1s;
}

.spinner .rect3 {
    -webkit-animation-delay: -1s;
    animation-delay: -1s;
}

.spinner .rect4 {
    -webkit-animation-delay: -0.9s;
    animation-delay: -0.9s;
}

.spinner .rect5 {
    -webkit-animation-delay: -0.8s;
    animation-delay: -0.8s;
}
